// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer.collapsed {
    height: 40px;
    /* Adjust as necessary for the button height */
    overflow: hidden;
    transition: height 0.3s ease;
}

.footer {
    transition: height 0.3s ease;
}

.footer button {
    width: 100%;
}

.footer .bi {
    position: absolute;
    left: 50%;
    top: 0;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,8CAA8C;IAC9C,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,MAAM;IACN,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".footer.collapsed {\n    height: 40px;\n    /* Adjust as necessary for the button height */\n    overflow: hidden;\n    transition: height 0.3s ease;\n}\n\n.footer {\n    transition: height 0.3s ease;\n}\n\n.footer button {\n    width: 100%;\n}\n\n.footer .bi {\n    position: absolute;\n    left: 50%;\n    top: 0;\n    background-color: #fff;\n    border: none;\n    border-radius: 50%;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
