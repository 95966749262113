// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Home.css */
.card-link {
    text-decoration: none;
    color: inherit;
}

.card-link:hover .card,
.card-link:focus .card {
    cursor: pointer;
    transform: scale(1.05);
    transition: transform 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;;IAEI,eAAe;IACf,sBAAsB;IACtB,0BAA0B;AAC9B","sourcesContent":["/* src/components/Home.css */\n.card-link {\n    text-decoration: none;\n    color: inherit;\n}\n\n.card-link:hover .card,\n.card-link:focus .card {\n    cursor: pointer;\n    transform: scale(1.05);\n    transition: transform 0.2s;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
