// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Sidebar.css */

.sidebar {
    position: relative;
    transition: width 0.3s;
    overflow: hidden;
    height: 100vh;
    background-color: var(--sidebar-bg);
    color: var(--sidebar-color);
}

.sidebar.open {
    width: 200px;
}

.sidebar.collapsed {
    width: 50px;
}

.toggle-btn {
    position: absolute;
    top: 5%;
    transform: translateY(-50%);
    right: 0;
    background-color: #fff;
    border: none;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    padding: 5px;
}

.toggle-btn i {
    font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;IACI,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,mCAAmC;IACnC,2BAA2B;AAC/B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,2BAA2B;IAC3B,QAAQ;IACR,sBAAsB;IACtB,YAAY;IACZ,4BAA4B;IAC5B,+BAA+B;IAC/B,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["/* src/components/Sidebar.css */\n\n.sidebar {\n    position: relative;\n    transition: width 0.3s;\n    overflow: hidden;\n    height: 100vh;\n    background-color: var(--sidebar-bg);\n    color: var(--sidebar-color);\n}\n\n.sidebar.open {\n    width: 200px;\n}\n\n.sidebar.collapsed {\n    width: 50px;\n}\n\n.toggle-btn {\n    position: absolute;\n    top: 5%;\n    transform: translateY(-50%);\n    right: 0;\n    background-color: #fff;\n    border: none;\n    border-top-right-radius: 50%;\n    border-bottom-right-radius: 50%;\n    padding: 5px;\n}\n\n.toggle-btn i {\n    font-size: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
