// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link.active {
    font-weight: bold;
    color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".nav-link.active {\n    font-weight: bold;\n    color: #007bff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
