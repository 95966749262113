export const config = {
  beam: {
    models: {
        "ru4zo": {
            name: "SDXL",
            targets: [
                "txt2img"
            ]
        },
        "ceoiy": {
            name: "Juggernaut XL",
            targets: [
                "txt2img"
            ]
        },
        "r5uhn": {
            name: "Better Than Words",
            targets: [
                "txt2img"
            ]
        },
        "cjs1m": {
            name: "Realistic Vision XL",
            targets: [
                "txt2img"
            ]
        },
        "kk5rw": {
            name: "Realistic Vision 5",
            targets: [
                "txt2img"
            ]
        },
        "q5i8v": {
            name: "URPM",
            targets: [
                "txt2img"
            ]
        },
        "av6pr": {
            name: "Chillout Mix",
            targets: [
                "txt2img"
            ]
        },
        "dz3ak": {
            name: "DucHaiten Real 3D",
            targets: [
                "txt2img"
            ]
        },
        "pozme": {
            name: "Deliberate",
            targets: [
                "txt2img"
            ]
        },
        "kv46s": {
            name: "Animate diff",
            targets: [
                "txt2vid"
            ]
        },
        "o2kb5": {
            name: "Animate LCM",
            targets: [
                "txt2vid"
            ]
        },
        "bskua": {
            name: "Audio LDM (sound)",
            targets: [
                "txt2aud"
            ]
        },
        "yh41l": {
            name: "Audio LDM (speech)",
            targets: [
                "txt2spch"
            ]
        }
    },
    authToken: 'MGZhMjMxNmIxZjU5ZmI3NmRlYjBhZmVmNTUwMDA2OTk6OTUwZDU3MWE4YzFiZDU0N2E5ODE1ZGEwZTkwMmU4MTI='
  },
  modelsLab: {
    apiKey: ''
  }
};
